import React from 'react'
import PageTitle from '../../../components/PageTitle'
import Layout from '../../../components/Layout'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'

class SuttonAwards extends React.Component {
  render() {
    return (
      <Layout>
        <PageTitle name="Sutton Awards" />
        <div>
          <h3>I. Purpose</h3>
          <p>
            To recognize Alabamians who have demonstrated leadership and
            outstanding accomplishment in the field of institutional research
            and related fields. The award should be a meaningful recognition of
            A5:B21 individuals who have provided exemplary service and
            leadership and who have actively supported and enhanced the goals of
            ALAIR.
          </p>
          <h3>II. Criteria</h3>
          <p>
            All eligible award recipients must at some time have been involved
            in institutional research or a related field in Alabama. Beyond
            that, at least one of the following criteria must be met.{' '}
          </p>
          <ol>
            <li>
              Service to ALAIR, SAIR, or AIR in the form of serving as an
              officer, committee member, or conference host; making
              presentations; and other forms of volunteer activity.
            </li>
            <li>
              A career in institutional research or a related field exemplifying
              leadership, accomplishment, and innovation.
            </li>
            <li>
              Contribution to the field of institutional research through
              specific accomplishments and innovative endeavors which enhance
              the field, institution, or community (local, state, nation).
            </li>
            <li>Retirement will not be a criterion.</li>
            <li>
              Former members of ALAIR and individuals who are not members of
              ALAIR and individuals who are not members of ALAIR will be
              eligible, as will former Alabamians who have been involved in
              institutional research or a related field in Alabama.
            </li>
          </ol>
          <h3>III. Selection Process</h3>
          <ol>
            <li>
              Annually, the Immediate Past President will chair the awards
              committee and develop a list of not more than three candidates for
              recommendation to the Executive Committee. The recommendation of
              the committee should be ranked. The President will appoint a
              chairperson who will then appoint the other members of the
              committee.
            </li>
            <li>
              An affirmative vote by the majority of the Executive Committee
              members present during voting is required for approval of a Joseph
              T. Sutton Award.
            </li>
            <li>
              Criteria and procedures for selecting The Joseph T. Sutton Award
              recipient are distributed annually to all ALAIR members.
            </li>
            <li>
              Only one award should be presented. However, there could be
              co-recipients, for example, in the case of two or more people
              working together on a significant body of research.
            </li>
            <li>
              The award should be presented at the ALAIR Annual Conference by
              the President and the Immediate Past-President (or his/her
              designee).
            </li>
          </ol>
          <h3>IV. Form of Award</h3>
          <p>
            The award recipient should be presented a plaque delineating his or
            her service or contribution to institutional research. In addition,
            a letter should be sent to the president or director of the
            recipient's institution or agency informing of the award.
          </p>

          <h3>ALAIR Sutton Awards Recipients</h3>
          <Table>
            <Thead>
              <Tr>
                <Th>Year</Th>
                <Th>Name</Th>
                <Th>Institution</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>2022</Td>
                <Td>Ms. Julianna Proctor</Td>
                <Td>The University of Alabama</Td>
              </Tr>
              <Tr>
                <Td>2021</Td>
                <Td>no award presented</Td>
                <Td></Td>
              </Tr>
              <Tr>
                <Td>2020</Td>
                <Td>Dr. Cara Mia Braswell</Td>
                <Td>University of South Alabama</Td>
              </Tr>
              <Tr>
                <Td>2019</Td>
                <Td>no award presented</Td>
                <Td></Td>
              </Tr>
              <Tr>
                <Td>2018</Td>
                <Td>no award presented</Td>
                <Td></Td>
              </Tr>
              <Tr>
                <Td>2017</Td>
                <Td>no award presented</Td>
                <Td></Td>
              </Tr>
              <Tr>
                <Td>2016</Td>
                <Td>Dr. Mimi Johnson</Td>
                <Td>Trenholm State Community College</Td>
              </Tr>
              <Tr>
                <Td>2015</Td>
                <Td>Andrew Luna</Td>
                <Td>University of North Alabama</Td>
              </Tr>
              <Tr>
                <Td>2014</Td>
                <Td>Alicia Simmons</Td>
                <Td>Jacksonville State University</Td>
              </Tr>
              <Tr>
                <Td>2013</Td>
                <Td>No award presented</Td>
                <Td> &nbsp;</Td>
              </Tr>
              <Tr>
                <Td>2012</Td>
                <Td>No award presented</Td>
                <Td> &nbsp;</Td>
              </Tr>
              <Tr>
                <Td>2011</Td>
                <Td>No award presented</Td>
                <Td> &nbsp;</Td>
              </Tr>
              <Tr>
                <Td>2010</Td>
                <Td>No award presented</Td>
                <Td> &nbsp;</Td>
              </Tr>
              <Tr>
                <Td>2009</Td>
                <Td>Mrs. Mary Beth Adams</Td>
                <Td>University of Alabama at Birmingham</Td>
              </Tr>
              <Tr>
                <Td>2008</Td>
                <Td>No award presented</Td>
                <Td> &nbsp;</Td>
              </Tr>
              <Tr>
                <Td>2007</Td>
                <Td>No award presented</Td>
                <Td> &nbsp;</Td>
              </Tr>
              <Tr>
                <Td>2006</Td>
                <Td>No award presented</Td>
                <Td> &nbsp;</Td>
              </Tr>
              <Tr>
                <Td>2005</Td>
                <Td>Mrs. Janis Colee Lowther</Td>
                <Td>Alabama Commission on Higher Education (ACHE)</Td>
              </Tr>
              <Tr>
                <Td>2004</Td>
                <Td>Dr. William &quot;Bill&quot; Fendley</Td>
                <Td>The University of Alabama</Td>
              </Tr>
              <Tr>
                <Td>2003</Td>
                <Td>Ms. Hannelore H. Delbridge</Td>
                <Td>The University of Alabama</Td>
              </Tr>
              <Tr>
                <Td>2002</Td>
                <Td>Dr. Jim Eck</Td>
                <Td>Samford University</Td>
              </Tr>
              <Tr>
                <Td>2001</Td>
                <Td>Dr. Myrtes Dunn Green</Td>
                <Td>Stillman College</Td>
              </Tr>
              <Tr>
                <Td>2000</Td>
                <Td>Mr. Sam Lowther</Td>
                <Td>Auburn University</Td>
              </Tr>
              <Tr>
                <Td>1999</Td>
                <Td>Dr. Harriott Calhoun</Td>
                <Td>Jefferson State Community College</Td>
              </Tr>
              <Tr>
                <Td>1998</Td>
                <Td>Mr. Ed Rutledge</Td>
                <Td>Alabama Commission on Higher Education (ACHE)</Td>
              </Tr>
              <Tr>
                <Td>1997</Td>
                <Td>Mrs. Deborah Dailey</Td>
                <Td>University of Alabama at Birmingham</Td>
              </Tr>
              <Tr>
                <Td>1996</Td>
                <Td>Dr. Al Searway</Td>
                <Td>Jacksonville State University</Td>
              </Tr>
              <Tr>
                <Td>1995</Td>
                <Td>Dr. Kay Staub</Td>
                <Td>The University of Alabama</Td>
              </Tr>
              <Tr>
                <Td>1994</Td>
                <Td>Mr. Alfred Yeager</Td>
                <Td>University of South Alabama</Td>
              </Tr>
              <Tr>
                <Td>1993</Td>
                <Td>Dr. Gerald Leischuck</Td>
                <Td>Auburn University</Td>
              </Tr>
            </Tbody>
          </Table>
        </div>
      </Layout>
    )
  }
}

export default SuttonAwards
